@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&family=PT+Sans:wght@400;700&display=swap');

.namebar {
    top: 0;
    height: 60px;
    color: #76fc4e;
    background-color: #1A1B28;
    font-family: "'El Messiri', sans-serif";
}

.namebar h3 {
    padding: 8px 120px 10px;
}


.page-nav {
    background-size: 100%;

    text-align: center;
    padding-top: 60px;
    background-color: #f3f4f8;
}

.page-nav ul {
    float: none;
    margin: auto;
    width: auto;
    list-style-type: none;
}

@media screen and (max-width: 992px) {
    .page-nav {
        background-size: auto;
    }
}

.page-nav h2 {
    font-size: 30px;
    width: 100%;
    font-weight: 500;
    color: #444;
}

@media screen and (max-width: 600px) {
    .page-nav h2 {
        font-size: 26px;
    }

    .namebar h3 {
        padding: 8px 30px 10px;
    }
}

.page-nav ul li {
    float: left;
    margin-right: 25px;
    margin-top: 10px;
    font-size: 16px;
    font-size: 0.93rem;
    font-weight: 600;
    color: #444;
}

.page-nav ul li i {
    width: 30px;
    text-align: center;
    color: #444;
}

.page-nav ul li a {
    color: #444;
    font-size: 0.93rem;
    font-weight: 600;
}


/* explore startups */

.explore {
    padding: 140px 10px 50px;
    margin: 200px 0px 150px;
    border-radius: 8px;
    /* width: 90%; */
    /* border: 1px solid #3dc3ff; */
    background-color: #1A1B28;
    box-shadow: 4.871px 10px 8px 0px rgba(0, 0, 0, 0.06);
    position: relative;
}

.explore .pic3 {
    padding: 15px;
    background-color: #ffffff;
    border-radius: 8px;
    width: 200px;
    height: 200px;
    border: 5px solid #76fc4e;
    overflow: hidden;
    margin: 0 auto;
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
}

/* .explore .email {
    font-size: small;
    color: rgb(173, 173, 173);
    float: right;
    overflow: hidden;
    margin: 10px;
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
  } */

.explore .description3 {
    font-size: 15px;
    color: rgb(173, 173, 173);
    line-height: 27px;
    margin: 0;
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
}

.explore .description3 p {
    font-size: medium;
}

.explore .description3 label {
    font-size: medium;
    font-weight: 900;
}

.explore .description3 .chip {
    background-color: #262734;
    border-radius: 6px;
    text-align: right;
    padding: 5px 20px;
    margin-bottom: 10px;
}


@media screen and (max-width: 670px) {

    /* .explore {
      margin-top: 120px;
      width: 300px;
    } */
    .explore .pic3 {
        width: 150px;
        height: 150px;
        top: -75px;
    }

    /* .explore .description3 .chip {
      text-align: center;
    } */
}
